<script>
/**
 * Dashboard Component
 */
export default {
  props: {},
  components: {},
  data() {
    return {
      show: false,
      note: null,
      confirm: null,
      close: null,
    };
  },
  created() {},
  beforeDestroy() {},
  mounted() {},
  methods: {
    showModal(note) {
      this.note = note;
      this.show = true;
      return new Promise((resolve, reject) => {
        this.confirm = () => {
          resolve(this.note);
        };
        this.close = () => {
          reject();
        };
      }).finally(() => {
        (this.note = null), (this.show = false);
      });
    },
  },
};
</script>

<template>
  <b-modal
    v-model="show"
    id="noteModal"
    :title="$t('general.note')"
    style="position: relative"
  >
    <b-form-textarea
      v-model="note"
      :placeholder="$t('general.enterNote')"
      rows="8"
    ></b-form-textarea>
    <template #modal-footer>
      <div class="w-100">
        <b-button variant="success" class="float-right" @click="confirm">
          {{ $t("general.save") }}
        </b-button>
        <b-button class="mr-2 float-right" @click="close">
          {{ $t("general.close") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
